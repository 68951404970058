<template>

   <router-view/>

</template>

<script></script>

<style lang="scss">
:root {
    --text-navbar-hover: #EC7520; /* #4ade80 380000*/
    --text-navbar: #fff;
    --scroll-bar-background: #4ade80;
    --scroll-bar-hover:#4D4D4D;
    --scroll-bar-track:#999999;
    --footer-text:#b4b4b4;
    --footer-background:#1a1a1a ;
    --footer-hover: #EC7520;
    --sfondo-agri: #1e5055a0;
}

* {
    top: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
}

button {
    cursor: pointer;
    appearance: none;
    border: none;
    outline: none;
    background: none;
}

#app {
    font-family: 'Roboto', 'Ubuntu', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;    
    scroll-behavior: smooth;
}

a {
  color: var(--text-navbar);
  font-size: 1rem;
  font-family: 'Roboto';
}
html body{
  overflow-x: hidden;
  display: flex;
  background-color: black;
  scroll-behavior: smooth;
}

/* SETTING FOR SCROLLBAR */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;

  
}
::-webkit-scrollbar-thumb {
  background: var(--scroll-bar-background);
  border-radius: 5px;
  
}
::-webkit-scrollbar-track {
  background: var(--scroll-bar-track);
  border-radius: 5px;
  box-shadow: inset 0 0 5px grey; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-bar-hover); 
}



</style>
