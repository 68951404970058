<template>

    <div class="rinnovabili" >
    <img src="@/assets/images/content_img/rinnovabili.jpg" class="img-rinnovabili" alt="">
        <a name="rinnovabili"></a> <!-- ANCORA -->
        <div class="titolo">
            Energie Rinnovabili
        </div>

        <div class="container-data">
            <div class="descrizione">
                Da oltre 10 anni siamo impegnati nello sviluppo, progettazione, costruzione e conduzioni di
                impianti per la produzione di energia da fonti rinnovabili come core business, per le
                controllate del gruppo.
                <br>
                La società si occupa, inoltre, di fornire consulenza e realizzare attività in proprio
                per la gestione globale di progetti integrati per il territorio e l’ambiente, le fonti 
                energetiche sostenibili, gli insediamenti turistico/ricettivi, gli insediamenti 
                produttivi integrati eco-agro-alimentari, industriali e agro-industriali, 
                dei progetti ambientali ecosostenibili, eco-energetici da fonti rinnovabili, 
                dei progetti di bioarchitettura integrata ed innovativa a forte risparmio energetico.
                <br>
                Gli ambiti in cui operiamo e sviluppiamo proposte e progetti per impianti destinati alla produzione
                di energia elettrica e termica sono:
                <br>  
                <div class="lista">
                    <p> Agrivoltaico</p>
                    <p>Biogas</p>
                    <p>Fotovoltaico</p>
                    <p>Idroelettrico</p>
                    <p>Parco Solare</p>
                    <p>Teleriscaldamento</p>
                    <p>Termodinamico</p>           
                </div>    
            </div>
        </div>

    </div>

</template>

<script>

export default {
    name: "EnergieRinnovabili",
}
</script>

<style lang="scss" scoped>

.rinnovabili {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    min-height: 60vh;
    overflow: hidden;
    
    .img-rinnovabili {
        display: flex;
        position: absolute;
        width: 100%;
        object-position: center center;
        object-fit: cover;
        opacity: 0.5;
        
    }
    

    .titolo {
        display: flex;
        width: 100%;      
        font-family: 'Ubuntu', sans-serif;
        font-size: 3rem;
        color: var(--text-navbar);
        font-weight: 500;
        padding: 3rem 3rem 3rem 3rem;      
        z-index: 0;  
    }

    .container-data {
        display: flex;
        flex-direction: row;
        width: 100%;        

        .descrizione {
            display: block;      
            width: 100%;  
            padding: 0rem 3rem 1rem 3rem;
            font-family: 'Montserrat', sans-serif;
            font-weight: 300;
            font-size: 1.5rem;
            color: var(--text-navbar);
            line-height: 2.25rem;
            z-index: 0;
            
            .lista  {
                padding-top: 0.75rem;
                p {
                    padding-top: 0.25rem;
                    font-size: 1.5rem; 
                }
            }
        }
    }
}

    
@media screen and (max-width: 1200px) {

    .rinnovabili {
       
        .img-rinnovabili {
            height: 100%;
        }

        .container-data {
            flex-direction: column;
            padding-bottom: 2rem;

            .descrizione {                
                padding-right: 3rem;
                font-size: 1.35rem;
                line-height: 2rem;

                .lista  {
                
                    p {
                        font-size: 1.35rem; 
                    }
                }
            }
        }
    }


    @media screen and (max-width: 768px) {
        .rinnovabili {

            .container-data {

                .descrizione {
                    font-size: 1.25rem;

                    .lista  {
                
                        p {
                            font-size: 1.25rem; 
                        }
                    }
                }                
            }
        }
    

        @media screen and (max-width: 600px) {

            .rinnovabili {
                
                .container-data {

                    .descrizione {
                        font-size: 1.2rem;
                        .lista  {
                
                            p {
                                font-size: 1.2rem; 
                            }
                        }

                    }                    
                }
            }


            @media screen and (max-width: 534px) {

                .rinnovabili {
                    .titolo {
                        font-size: 2.7rem;
                    }
                } 
            }
        }
    }
}

</style>