<template>
  <div class="container">
    <a href="#" class="false" id="up-button" v-bind:on-scroll="handleScroll">
      <span class="material-symbols-outlined">expand_less</span>
    </a>
  </div>
</template>

<script>

import { ref } from 'vue';

export default {
  name: 'UpButton',
  data() {
    return {
      lastKnownScrollPosition: 0,
      is_visible: ref(false), 
      positiony: null,  
        
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
     handleScroll() {
     this.lastKnownScrollPosition = window.scrollY;
     if (this.lastKnownScrollPosition > 600 && this.lastKnownScrollPosition > this.positiony) {
      document.getElementById('up-button').className = "is-visible"; 
      this.positiony = this.lastKnownScrollPosition;     
      }
      else {
        document.getElementById('up-button').className = "false";
        this.positiony = window.scrollY;
      }
    },
  }
}

</script>

<style lang="scss" scoped>

.container {
  display: flex;
  justify-content: right;
  margin-right: 1rem;
  transition: 0.5s ease-in;
  overflow-x: hidden;
  z-index: 1;

  a {   
    display: flex;  
    flex-direction: column;
    width: max-content;
    background: var(--footer-hover); 
    color: var(--text-navbar);
    border-radius: 1.25rem;
    border: 1px solid var(scroll-bar-hover);
    transition: 0.3s ease-out;
    
    .material-symbols-outlined {  
      font-size: 2.5rem;
      text-align: center;
      transition: 0.3s ease-out;  
      }
    }
  
  .false {
    opacity: 0;
    position: fixed;
    z-index: 0;
    transition: 0.3s ease-out;
    margin-top: 40vh;
  }
  
  .is-visible {
    position: fixed;
    opacity: 1;
    transition: 0.5s ease-in;
    margin-top: 52.5vh;
  }
}  

</style>