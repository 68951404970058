<template>
  <div class="container-home">
    <img class="img-nav" src="@/assets/images/navbar_image/home_pic.jpg" alt="home_pic">
  </div>
  <div class="container-home">
    <NavBar />
    <HomeMessage />
  </div>
  <div class="container-home">
    <AboutUs />
  </div>
  <div class="container-home">
    <EnergieRinnovabili />
  </div>
  <div class="container-home">
    <ComunitaEnergetiche />
  </div>
  <div class="container-home">
    <ContactUs />
  </div>
    <div class="container-home">
    <FooTer />
  </div>  
  <div class="container-home">
    <UpButton />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import AboutUs from '@/components/AboutUs.vue';
import ContactUs from '@/components/ContactUs.vue';
import FooTer from '@/components/FooTer.vue';
import UpButton from '@/components/UpButton.vue';
import ComunitaEnergetiche from '@/components/ComunitaEnergetiche.vue';
import HomeMessage from '@/components/HomeMessage.vue';
import EnergieRinnovabili from '@/components/EnergieRinnovabili.vue';



export default {
  name: 'HomeView',
  components: {
    NavBar,
    HomeMessage,
    AboutUs,
    ComunitaEnergetiche,
    ContactUs,
    FooTer,
    UpButton,
    EnergieRinnovabili
  }
}
</script>

