<template>
    
    <div class="contact-us">
        <a name="contatti"></a><!-- ANCORA -->
        <img src="@/assets/images/content_img/idro.png" class="img-contact" alt="">
        <div class="paragrafo">
            
            <div class="title">Contatti</div>
            <div class="wrap">
                <div class="intestazione">
                    RECAPITI
                    <text>SEDE LEGALE</text>
                    <p>Via Cardinale Scipione Borghese, 37</p>
                    <p>00031 Artena (RM)</p>
                    <text>CF | P. IVA</text>
                    <p>10581421004</p><br>
                </div>

                <div class="recapiti">
                    AMMINISTRAZIONE
                    <text>eMAIL</text>
                    <a href="mailto:amministrazione@energienuove.it?subject=Richiesta informazioni">
                        amministrazione@energienuove.it
                    </a>
                    <text>PEC</text>
                    <p>certificata@pec.energienuove.biz</p><br>
                </div>
            </div>

        </div>
    </div>
        
</template>

<script>

export default {
    name: 'ContactUs',
}

</script>

<style lang="scss" scoped>

.contact-us {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-family: 'Ubuntu', sans-serif;
    overflow: hidden;

    .img-contact {
        display: flex;
        min-height: 50vh;
        min-width: 300px;
        flex-basis: 50%;
        flex-grow: 1;
        margin: 0 auto;
        object-fit: cover;
        object-position: 20% center;
        opacity: 0.8;
        overflow-x: hidden;
    }

    .paragrafo {
        display: flex;
        flex-basis: 50%;
        flex-direction: column;

        .title {
            display: block;      
            height: fit-content;    
            font-family: 'Ubuntu', sans-serif;
            font-size: 3rem;
            color: var(--text-navbar);
            padding: 3rem 3rem 3rem 3rem;            
        }
    }
    
    .wrap {
        display: flex;
        flex-wrap: wrap;       
        flex-direction: row;
        
        .intestazione, .recapiti {
            display: flex;
            flex-direction: column;
            flex-basis: 50%;
            min-width: 360px;
            padding: 0rem 1.5rem 1rem 3rem;
            font-family: 'Ubuntu', sans-serif;
            font-size: 1.75rem;
            font-weight: 600;
            color: var(--text-navbar-hover);

            text {
                display: flex;
                font-family: 'Montserrat';
                padding-top: 2rem;
                color: var(--text-navbar);
                font-size: 1.25rem;
            }

            p {
                display: flex;
                padding-top: 0.5rem;
                color: var(--text-navbar);
                font-family: 'Montserrat', sans-serif;
                font-size: 1rem;
                font-weight: 400;
                min-width: max-content;
            }

            a {
                font-family: 'Montserrat', sans-serif;
                font-weight: 400;
            }
            
        }
    }
}

</style>