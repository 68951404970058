<template>

<!-- NAVBAR -->  
<nav :class="`${is_clicked && 'is-clicked'}`" id="nav">
  <div :class="`${'header'}`" id="fixed"  v-bind:on-scroll="handleScroll">	
    <div class="logo">	
      <router-link to="">
      <img src="@/assets/images/logo/logo_bianco_trasprente.png" alt="">
      </router-link>
    </div>	

    <div class="flex"></div>

    <!-- Navigation Menu-->
    <div class="navigation-menu">

      <div class="navigation">
        <div class="container-navigation">
          <a href="#chisiamo" @click="ClearMenu">
          <span class="text">Chi siamo</span>
          </a>
        </div>
      </div>

      <div class="navigation" @mouseleave="CloseSubMenu">
        <div class="container-navigation"> 
          <a href="#rinnovabili" @click="ClearMenu">
          <span class="text">Energie Rinnovabili</span>
          </a>
        </div>
      </div>
      
      <div class="navigation">
        <div class="container-navigation">
          <a href="#community" @click="ClearMenu">
          <span class="text">Comunità Energetiche</span>
          </a>
        </div>
      </div>  
      
      <div class="flex"></div>

      <div class="navigation">
        <div class="container-navigation">
          <a href="#contatti" @click="ClearMenu">
          <span class="text">Contatti</span>
          </a>
        </div>
      </div>

    </div>

    <div class="menu-toggle-wrap" >
      <button @click="OpenMenu" class="toggle-button">
        <span class="material-symbols-outlined">menu</span>
      </button>
    </div>	

  </div>
</nav>


<!-- SIDEBAR -->

  <aside :class="`${is_opened && 'is-closed'}`">
    <div class="asidebar">
      
      <div class="menu-title">
        <div class="logo">	
          <img src="@/assets/images/logo/logo_bianco_trasprente.png" alt="">
        </div>    

        <div class="flex"></div>

        <div class="menu-toggle-wrap">
        <button class="toggle-button" @click="CloseMenu">
          <span class="material-symbols-outlined">close</span>
        </button>
        </div>

      </div>

      <div class="links">

        <a href="#chisiamo">
          <button @click="ClearMenu">
            <span class="material-symbols-outlined">group </span>
            <span class="text">Chi siamo</span>
          </button>
        </a>

        <a href="#rinnovabili">
          <button @click="ClearMenu">
            <span class="material-symbols-outlined">solar_power</span>
            <span class="text">Energie Rinnovabili</span>
          </button>
        </a>

        <a href="#community">
          <button @click="ClearMenu">
            <span class="material-symbols-outlined">groups</span>
            <span class="text">Comunità Energetiche</span>
          </button>
        </a>

        <div class="flex"></div>

          <a href="#contatti">
            <button @click="ClearMenu">
              <span class="material-symbols-outlined">mail</span>
              <span class="text">Contatti</span>
            </button>
          </a>

      </div>

    </div>  
  </aside>
   
</template>

<script>

import { ref } from 'vue';

export default {
  name: 'NavBar',
  data() {
    return {
      is_clicked: ref(false),
      is_opened: ref(true),
      lastKnownScrollPosition: 0,
      positiony: 0,
    }
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
  },

  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    
    OpenMenu() {
      this.is_clicked = !this.is_clicked;
      this.is_opened = !this.is_opened;
      document.getElementById("fixed").style.opacity = "0";
      
    },

    CloseMenu() {
      this.is_clicked = !this.is_clicked;
      this.is_opened = !this.is_opened;
      document.getElementById("fixed").style.opacity = "1";
    },

    ClearMenu() {
      this.positiony = 0;
      this.is_opened = true;
   
    },

    handleScroll() {
     this.lastKnownScrollPosition = window.scrollY;

     if (this.lastKnownScrollPosition > this.positiony) {
        document.getElementById("fixed").style.opacity = "0";
        document.getElementById("fixed").style.backgroundColor = "rgba(0, 0, 0, 0)";
        this.positiony = this.lastKnownScrollPosition;
        this.is_opened = true; 
      }

      else if (window.scrollY < 10) {
        document.getElementById("fixed").style.backgroundColor = "rgba(0, 0, 0, 0)";
        document.getElementById("fixed").style.opacity = "1";
        this.is_clicked = false;
      }

     else {
        document.getElementById("fixed").style.opacity = "1";
        document.getElementById("fixed").style.backgroundColor = "rgba(0, 0, 0, .75)";
        this.positiony = window.scrollY;
        this.is_opened = true;
      }
    },

  }
}

</script>

<style lang="scss" scoped>

/* NAVBAR */
nav {
  display: flex;
  width: 100%;
  z-index: 1;

 /* da NAVBAR a SIDEBAR */
 
  &.is-clicked {

    .header {
      transition: 0.3s ease-in;
      opacity: 0;
    }
  }

  .header {
    display: flex;   
    width: 100%;
    padding: 0rem 12.5%;
    height: 8rem;
    position: fixed;
    transition: 0.3s ease-in;

    .flex {
      flex: 1 1 0;
    }

    .logo {
      display: flex;
      align-items: center;
      transition: 0.3s ease-out;

      img {
        height: 4rem;
      }
    }
    
    .menu-toggle-wrap{
      display: flex;
      align-items: center;
      opacity: 0;
      width: 0rem;
      transition: 0.3s ease-out;
      
      button{
        display: flex;
        color: var(--text-navbar);
        transition: 0.3s ease-out;

        .material-symbols-outlined {
          font-size: 0rem;
          transition: 0.3s ease-out;
        }
      }
    }

    

    /* Navigation Menu */
    .navigation-menu {
      display: flex;
      flex-direction: row;
      min-width: 60%;
      
      .navigation {
        display: flex;
        flex-direction: column;
        height: max-content;
        min-width: max-content;
        padding: 3.3rem 1rem 0rem 2rem;
        
        .flex {
          flex: 1 1 0;
        }

        .container-navigation {
          display: flex;
          flex-direction: column;   

          .text {
              font-family: 'Montserrat', sans-serif;
              color: var(--text-navbar);
              font-size: 1.2rem;
              min-width: max-content;
            }     
            
            .false {
              display: flex;
              flex-direction: row;
             
              .tasto {
                display: flex;
                flex-direction: row;
                color: var(--text-navbar);

                .text {
                  font-family: 'Ubuntu', sans-serif;
                  font-size: 1.2rem;
                  min-width: max-content;
                }

                .material-symbols-outlined {
                  color: var(--text-navbar);
                }
              } 
              
              .submenu {
                display: flex;
                flex-direction: column;
                z-index: -1;
                padding-top: 0rem;
                transition: 0.3s ease-out;
                .button {
                  opacity: 0;
                  display: flex;
                  transition: 0.3s ease-out;
                  .text-submenu {
                    font-size: 1.2rem;
                    transition: 0.3s ease-out;
                  }
                }
              }
            }
                      
          /* SOTTOMENU NAVBAR */

          .is-toggled {
            display: flex;
            flex-direction: row;
            transition: 0.3s ease-in;
           
        
            .tasto {
              display: flex;
              transition: 0.3s ease-in;
              width: max-content;
              
              .text {
                color: var(--text-navbar-hover);
                border-bottom: 1px solid var(--text-navbar-hover);
                transition: 0.3s ease-in;
              }      

              .material-symbols-outlined {
                color: var(--text-navbar-hover);
                transform: rotate(-180deg);
                transition: 0.3s ease-in;
              }
            }

            .submenu {
              display: flex;
              flex-direction: column;
              transition: 0.3s ease-in;
              padding-top: 0.5rem;        

              .button {
                display: flex;
                align-items: center;
                font-family: 'Ubuntu', 'Roboto', sans-serif;
                color: var(--text-navbar);
                transition: 0.3s ease-in;
                padding: 0.5rem 0rem;
               
                .text-submenu {
                  display: flex;
                  flex-direction: column;
                  height: 1.5rem;
                  font-size: 1.2rem;
                }

                &:hover {
                  transition: 0.3s ease-in; 
                  .text-submenu {
                    color: var(--text-navbar-hover);
                    border-bottom: 1px solid var(--text-navbar-hover);
                    
                  }
                }                
              }
            }
          }
        }
      }
    }  
  }

/* MEDIA SCREEN NAVBAR*/
  @media screen and (max-width: 1200px) {
    transition: 0.3s ease-out;
    .header {
      display: flex;
      width: 100%;
      padding: 0;
      transition: 0.3s ease-out;
     
      .menu-toggle-wrap{
        margin-right: 3rem;
        opacity: 1;
        width: auto;
        z-index: auto;
        transition: 0.3s ease-out;
        button {
          .material-symbols-outlined {
            font-size: 3rem;
          }
        }
      }  
      
      .navigation-menu {
        display: flex;
        min-width: 0rem;
        width: 0;
        opacity: 0;
        z-index: -1;
      }

      .logo {
        padding-left: 3rem;
        img {
          height: 4rem;
        }
      }
    }

    @media (max-width: 768px) {
    
      @media (max-width: 520px) {
        .header {
          
          .logo {
            padding-left: 3rem;
          }

            .menu-toggle-wrap{
              margin-right: 1.5rem;
              button {
              .material-symbols-outlined {
                font-size: 2.5rem;
                
                }
              }
            }
          }

        @media (max-width: 390px) {
          .header {
            height: 6.5rem;
            transition: 0.3s ease-out;

            .menu-toggle-wrap{
              button {

              .material-symbols-outlined {
                font-size: 2rem;
                }
              }
            }  

            .logo {
              padding-left: 2rem;
              img {
                height: 3.5rem;
              }
            }
          }
        }
      }
    }
  }
}

/* ASIDE MENU */


aside {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100vh;
  align-content: center;
  border-end-end-radius: 1rem;
  transition: 0.3s ease-in;
  z-index: 10;

  &.is-closed {
    display: flex;
    width: 0rem;
    opacity: 0;
    margin-left: -25rem;  
    transition: 0.3s ease-out; 
       
    .header {
      display: flex;
      width: 0rem;
      opacity: 0;
      transition: 0.3s ease-out;  
    }   
  }

  .asidebar {
    flex-direction: column;
    display: flex;   
    width: 40%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    transition: 0.3s ease-out;  
    overflow-y: auto;
    

    .menu-title {
      display: flex;
      height: 100%;
      height: 7.75rem;
      margin: 0rem 1rem 0rem 3rem;   /* (top, right, bottom, left) */
      transition: 0.3s ease-out;
      
      .flex {
        flex:1 1 0;
      }
      
      .menu-toggle-wrap{
        display: flex;
        align-items: center;
        margin-right: 0.5rem;  
        transition: 0.3s ease-out;
        
        button{
          display: flex;
          color: var(--text-navbar);
          transition: 0.3s ease-out;
  
          .material-symbols-outlined {
            font-size: 3rem;
            transition: 0.3s ease-out;
          }

          &:hover {  
              color: var(--text-navbar-hover);
            }
        }
      }
  
      .logo {
        display: flex;
        align-items: center;
        transition: 0.3s ease-out;
  
        img {
          height: 4rem;
          transition: 0.3s ease-out;
        }
      }
    }

    /* ITEM MENU ASIDE*/

    .links {
      display: flex;
      flex-direction: column;
      padding-left: 3rem;
      padding-top: 2rem;
      height: 80%;
      transition: 0.3s ease-out;
           

      .flex {
        flex: 1 1 0;
        transition: 0.3s ease-out;
      }
      button{
        display: inline-flex;
        align-items: center;
        margin: 1rem .25rem;
        color: var(--text-navbar);
        width: 20rem;
       
        .text {
          font-family: 'Ubuntu', sans-serif;
              color: var(--text-navbar);
              font-size: 1.25rem;
              
              min-width: max-content;

              &:hover {
                color: var(--text-navbar-hover);
                border-bottom: 1px solid var(--text-navbar-hover);
              }
        }

        .material-symbols-outlined {
          display: flex;
          font-size: 2.5rem;
          padding-right: 1.5rem;
          transition: 0.2s ease-out;
        }
        &:hover {  
              color: var(--text-navbar-hover);
            }
      
        }
      }
  }

  @media screen and (max-width: 1024px) {

    .asidebar{
      width: 50%;
      height: max(90vw, 100vh);

      .links {      
        padding: 3rem 3rem;       
      }
    }

    @media screen and (max-width: 850px) {

      .asidebar{
        width: 65%;
      }

      @media screen and ( max-width: 630px) {

        .asidebar{
          width: 80%;
        }


        @media screen and (max-width: 520px) {
        
          .asidebar{
            width: 100%;
          
              .menu-title {  
                .menu-toggle-wrap {
                  button{
                    .material-symbols-outlined {
                      font-size: 2.5rem;
                      transition: 0.3s ease-out;
                    }
                  }  
                }
              }
           
          }

          @media screen and (max-width: 390px) {
            .asidebar{
              width: 100%;
              align-items: left;
              overflow-x: hidden;
              .menu-title {
                height: 6.5rem;
  
                .menu-toggle-wrap {
                  button{
                    .material-symbols-outlined {
                      font-size: 2rem;
                      transition: 0.3s ease-out;
                    }
                  }  
                }
                .logo{
                  margin-left: -1rem;
                  img {                    
                    height: 3.5rem;
                  }
                }
              }

              .links {
                padding: 2.5rem 2rem;

                .item {
                  margin: .5rem .25rem;
                  .text {
                    font-size: 1rem;
                    transition: 0.3s ease-out;
                    cursor: pointer;
                  }

                  .material-symbols-outlined {
                    transition: 0.3s ease-out;
                    font-size: 2rem;
                    padding-right: 1rem;
                  }
                }

              }
            }
          }
        }  
      }  
    }  
  }
}

</style>
