<template>
    <div class="message">
        <div class="primo-blocco fadein-up">
            Il futuro è net ZERO, inizia oggi
        </div>
        
        <div class="secondo-blocco fadein-up">
            La transizione energetica e lo sviluppo sostenibile sono le sfide più importanti che abbiamo mai affrontato.
            Allo stesso tempo, rappresentano un'pportunità da cogliere subito.             
        </div>

        <button class="button fadein-static">
            <a href="mailto:amministrazione@energienuove.it?subject=Richiesta informazioni">
            INIZIA OGGI
            </a>
        </button>
    </div>
</template>

<script>

export default {
    name: 'HomeMessage',
}
</script>

<style lang="scss" scoped>

.message {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    align-items: center;
    position: absolute;
    overflow: hidden;
    margin-bottom: -5vh;

    .primo-blocco {
        display: flex;
        flex-direction: column;
        position: absolute;
        transform: translateY(20vh);
        word-wrap: normal;
        width: 100%;
        padding: 2.5% 12.5% 2% 12.5%;    
        font-family: 'Montserrat', sans-serif !important;
        font-size: 5rem;
        font-weight: 700;
        color: var(--footer-hover);
        text-rendering: optimizeLegibility;
    }

    .secondo-blocco {
        display: flex;
        position: absolute;
        flex-direction: column;
        position: absolute;
        transform: translateY(45vh);
        word-wrap: normal;
        width: 100%;
        padding: 2.5% 12.5% 5% 12.5%;    
        font-family: 'Montserrat', sans-serif !important;
        font-size: 2rem;
        font-weight: 400;
        line-height: 2.8rem;
        letter-spacing: 1px;
        color: var(--text-navbar);
        text-rendering: optimizeLegibility;
    }

    .button {
            display: flex;
            flex-direction: row;
            transform: translateY(80vh);
            align-items: center;
            height: 3.75rem;
            
            a{  
                display: flex;
                flex-direction: column;
                height: 3.75rem;
                border: 1.5px solid var(--text-navbar);
                border-radius: 0.5rem;
                background: rgba(0, 0, 0, .05);
                padding: 0rem 2rem;
                font-family: 'Montserrat', sans-serif !important;
                font-weight: 600;
                letter-spacing: 1.5px;
                font-size: 1.2rem;
                justify-content: center;
            }
        }
}


@media screen and (max-width: 1200px) {

    .message {
        height: 100vh;

        .primo-blocco {
            font-size: 4rem;
        }

        .secondo-blocco {
            transform: translateY(45vh);
            font-size: 1.6rem;
            letter-spacing: 0px;
        }

        .button {
            transform: translateY(80vh);
        }
    }
 
    @media screen and (max-width: 768px ) {

        .message {
            height: 100vh;

            .secondo-blocco {
                transform: translateY(47.5vh);
            }
        }
    
        @media screen and (max-width: 540px ) {
            .message {
                height: 100vh;
                    
                    .primo-blocco{
                        transform: translateY(20vh);
                        font-size: 2.75rem;   
                            
                    }
                    .secondo-blocco{
                        
                        font-size: 1.2rem;       
                        line-height: 2rem;   
                        font-weight: 400;         
                    }

                    .button  {
                        transform: translateY(75h);
                        display: flex;
                        width: 100%;
                        padding: 0rem 3rem;

                        a{  
                            width: 100%;
                        }

                    }
                }

            
            @media screen and (max-width: 480px ) {    

                .message {
                    height: 100vh;
                    .primo-blocco {
                        transform: translateY(20vh);
                    }
                    .secondo-blocco {
                        transform: translateY(45vh);
                    }
                }
            
                @media screen and (max-width: 389px ) {
                    .message {
                        height: 100vh;
                        
                        .primo-blocco{
                            font-size: 2.5rem;                    
                        }
                        .secondo-blocco{
                            font-size: 1rem;       
                            line-height: 1.65rem;    
                            font-weight: 400;         
                        }

                        .button  {

                            a{  
                                font-weight: 500;
                            }

                        }
                    }
                }
            }
        }
    }
}

</style>