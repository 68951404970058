<template>    
    <!--FOOTER-->
    <footer class="footer">
 
        <div class="logocolor">
            <img src="../assets/images//logo/logocolor.png" alt="">
        </div>
        <div class="content">

            <!--PRIMO ITEM-->
            <div class="text">
                
                Siamo un'impresa in grado di accompagnare
                il settore pubblico e privato verso lo sviluppo sostenibile.
                Partecipiamo a progetti energetici e ambientali per abbattere la carbon footprint e migliorare la
                qualità dei territori fortemente antropizzati. 
                Crediamo nell'autoconsumo di energia termica ed elettrica autoprodotta da fonti rinnovabili. 
                Supportiamo la costituzione di Comunità Energetiche Rinnovabili,
                l'installazione di Parchi Agrisolare, Agrivoltaico e la progettazione di impianti 
                alimentati da fonti rinnovabili.
                Promuoviamo la salvaguardia e la tutela dell'ambiente per le future generazioni.
                Insieme a voi possiamo migliorare il mondo in cui viviamo.   
                
            </div>
        
             <!--SECONDO ITEM-->
            <div class="container-link">
                <text>LINK UTILI</text>
                <button class="link">
                    <a href="#chisiamo" @click="ClearMenu">
                    <span>Chi siamo</span>
                    </a> 
                </button>
                <button class="link" >
                    <a href="#rinnovabili" @click="ClearMenu">
                    <span>Energie Rinnovabili</span>
                    </a>
                </button>
                <button class="link" >
                    <a href="#community" @click="ClearMenu">
                    <span>Comunità Energetiche</span>
                    </a> 
                </button>
                <button class="link" >
                    <a href="#contatti" @click="ClearMenu">
                    <span>Contatti</span>
                    </a>
                </button>
            </div>  

            <!--TERZO ITEM-->

            <div class="recapiti">
                <text>RECAPITI</text>
                <div class="intestazione">
                <h3>Sede Legale</h3>
                <li>Via Cardinale Scipione Borghese, 37</li>
                <li>00031 Artena (RM)</li><br>
                <h3>Capitale sociale</h3>
                <li>€ 1.500.000,00 i.v.</li><br>
                <h3>Codice Fiscale e P. IVA</h3>
                <li>10581421004</li><br>
                <h3>N. REA</h3>
                <li>RM - 1241505</li>
                </div>
                
            </div>

        </div>

        <div class="copyright">
            <div class="privacy-cookie">
                <text>Tutti i diritti riservati ©2022 Energie Nuove S.r.l.</text>
                <div class="button">
                <a href="https://www.iubenda.com/privacy-policy/49628062">Privacy Policy</a>
                <a href="https://www.iubenda.com/privacy-policy/49628062/cookie-policy">Cookie Policy</a>
                </div>
            </div>    
        </div>

    </footer>
</template>


<script>
import { ref } from 'vue';
export default {
    name: "FooTer",  
    data() {
    return {
      is_clicked: ref(false),
      is_opened: ref(true),
      lastKnownScrollPosition: 0,
      positiony: 0,
    }
  },

  created () {
    window.addEventListener('scroll', this.handleScroll);
  },

  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {

    ClearMenu() {
      this.positiony = 0;
      this.is_opened = true;
    },
    
    handleScroll() {
     this.lastKnownScrollPosition = window.scrollY;

     if (this.lastKnownScrollPosition > this.positiony) {
        document.getElementById("fixed").style.opacity = "0";
        document.getElementById("fixed").style.backgroundColor = "rgba(0, 0, 0, 0)";
        this.positiony = this.lastKnownScrollPosition;
      }

    },

  }
}

</script>

<style lang="scss" scoped>


.footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100wh;
    font-weight: 400;
    line-height: 1.5rem;
    background: var(--footer-background);
    color: var(--footer-text);
    overflow-x: hidden;
      
    
        
    .logocolor {
        display: flex;
        padding: 2rem 1rem 1rem 3rem; /* (top, right, bottom, left) */
        width: fit-content;

        img {   
        height: 4rem;
        }
    }

    .content {
        display: flex;
        flex-wrap: wrap;
        
        .text {
            display: flex;
            flex-basis: 30%;
            flex-grow: 1;
            padding: 0rem 2rem 2rem 3rem;
            margin: 0;
            font-size: 1rem;
            font-family: 'Montserrat', sans-serif;            
        }

        .container-link {
            display: flex;
            flex-direction: column;
            flex-basis: 20%;
            padding: 0rem 2rem 2rem 3rem;

            text {
                font-family: 'Montserrat', sans-serif !important;
                font-size: 1.5rem;
                font-weight: 500;
                color: var(--text-navbar-hover);
                padding-bottom: 1.5rem;
            }

            .link {
                display: flex;
                justify-items: start;
                padding-bottom: 1rem;
                width: max-content;

                span {
                    display: flex;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 1.25rem;
                    color: var(--footer-text);
                    padding-bottom: 1rem;
                    font-weight: 500;
                }
            }
        }

        .recapiti {
            display: flex;
            flex-direction: column;
            flex-basis: 30%;
            padding: 0rem 2rem 2rem 3rem;

            text {
                font-family: 'Montserrat', sans-serif;
                font-size: 1.5rem;
                font-weight: 500;
                color: var(--text-navbar-hover);
                padding-bottom: 1.5rem;
            }

            .intestazione {
                h3 {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 600;
                    font-size: 1rem;

                }

                li {
                    font-family: 'Montserrat', sans-serif;
                    list-style-type: none;
                    width: max-content;
                }
            }
        }
    }

    .copyright {
        display: flex;
        padding-bottom: 1rem;
        height: 100%;
        

        .privacy-cookie {
            display: flex;
            width:100%;
            height: 2rem;
            align-items: center;
            justify-content: center;
            margin-bottom: 2rem;     

            text {
                display: flex;
                font-family: 'Montserrat', sans-serif;
                color: var(--footer-text);
                min-width: max-content;
                margin: 0rem 2rem;
            }

            .button {
                display: flex;

                a {
                    font-family: 'Montserrat', sans-serif;
                    color: var(--footer-text);
                    width: max-content;
                    margin: 0rem 2rem;
                    cursor: pointer;
                }
            }
        }
    }


    @media screen and (max-width: 880px) {

        .content {

            .text {
            display: flex;
            flex-basis: 100%;
            flex-grow: 1;
            padding-right: 2.5rem;
            }

            .container-link{
                flex-basis: 50%;
            }

            .recapiti{
                flex-basis: 50%;
            }
        }

        @media screen and (max-width: 670px) {

            .copyright {
                display: flex;
                margin-top: 1rem;

                .privacy-cookie {
                    display: flex;
                    flex-direction: column;
                    
                    text {
                        display: flex;
                        font-size: 85%;   
                        margin-bottom: 0.5rem;
                    }

                    .button {
                        display: flex;

                        button {
                            display: flex;
                            margin: 0.40rem 1.5rem;

                        
            
                        }
                    }
                }
            }
        }
    }           
}
    
</style>