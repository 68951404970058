<template>
    
    <div class="community">
        <a name="community"></a> <!-- ANCORA -->
        <img src="@/assets/images/content_img/community.png" class="img-community media" alt="">
        <div class="paragrafo">
            
            <div class="title">Comunità Energetiche</div>
            <div class="wrap">
                <div class="intestazione">
                    <text>
                    Una Comunità Energetica Rinnovabile (CER) è un soggetto giuridico di diritto autonomo il cui obiettivo è produrre
                    energie elettrica per l'autoconsumo.
                    </text>  
                    <span>
                    Supportiamo lo sviluppo delle CER anche mediante lo sviluppo o la cessione di
                    rami d'azienda in possesso di tutte le autorizzazioni e i permessi a costruire e alla conduzione di impianti
                    fotovoltaici con potenza fino a 1 MWp oltre alle infrastrutture necessarie per la cessione in rete dell'energia
                    elettrica prodotta. 
                    </span>
                    <br>
                    <button>
                        <a href="mailto:amministrazione@energienuove.it?subject=Richiesta informazioni CER">
                        RICHIEDI INFORMAZIONI
                        </a>
                    </button>
                </div>               
            </div>

        </div>
        <img src="@/assets/images/content_img/community.png" class="img-community no-media" alt="">
    </div>
        
</template>

<script>

export default {
    name: 'ComunitaEnergetiche',
}

</script>

<style lang="scss" scoped>

.community {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: var(--sfondo-agri);
    overflow: hidden;

    .img-community {
        display: flex;
        min-height: 50vh;
        flex-basis: 50%;
        flex-grow: 1;
        margin: 0 auto;
        object-fit: cover;
        object-position: right center;
        overflow-x: hidden;
    }

    .paragrafo {
        display: flex;
        flex-basis: 50%;
        flex-direction: column;

        .title {
            display: flex;      
            height: fit-content;    
            font-family: 'Ubuntu', sans-serif;
            font-size: 3rem;
            color: var(--text-navbar);
            font-weight: 500;
            padding: 3rem 3rem 3rem 3rem;            
        }
    }

    .intestazione {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 360px;
        padding: 0rem 3rem 3rem 3rem;
        font-family: 'Ububtu', sans-serif;
        font-weight: 300;

        text {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            font-family: 'Ubuntu', sans-serif;
            font-size: 2.5rem;
            font-weight: 400;
            line-height: 3rem;
            color: var(--text-navbar-hover);
        }
        span {
            padding: 3rem 0rem 1rem 0rem;
            font-family: 'Montserrat', sans-serif;
            line-height: 2.5rem;
            font-weight: 300;
            font-size: 1.5rem;
            color: var(--text-navbar);
        }

        button {
            display: flex;
            flex-direction: column;
            align-items: center;
            a{  
                display: flex;
                flex-direction: column;
                height: 3.75rem;
                border: 1.5px solid var(--text-navbar);
                border-radius: 0.5rem;
                padding: 0rem 2rem;
                font-family: 'Montserrat', sans-serif;
                font-weight: 600;
                font-size: 1.2rem;
                justify-content: center;
            }
        }
    }   

    .media {
            opacity: 0;
            max-width: 0;
        }
        
     .no-media {
         opacity: 0.8;
     }

}

@media screen and (max-width: 1024px)  {

    .community {

        .wrap {

            .intestazione {

                text {
                    font-size: 1.5rem;
                    line-height: 2rem;
                }
                span {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    padding: 2rem 0rem 1rem 0rem;
                }
                button {
                    
                    a{
                        width: 100%;
                    }
                }
            }
        }
    }
    

    @media screen and (max-width: 720px) {
        .community {

            .no-media {
                opacity: 0;
                z-index: -1;
                max-width: 0px;
            }
            
            .media {
                opacity: 0.8;
                min-width: 100% ;
                
            }
            .paragrafo {
                min-width: 100%;
            }
        }
        
        @media screen and (max-width: 540px ) {
            .community {
                .paragrafo{
                    .title {
                        font-size: 2.7rem;
                    }
                    
                    .intestazione {
                        
                        text {
                            
                            line-height: 2rem;
                        }

                        span {
                            font-size: 1.2rem;
                            line-height: 1.75rem;
                        }
                    }
                }
            }
            
            @media screen and (max-width: 390px ) {
                .community {
                    .paragrafo{
                        .title {
                            font-size: 2.5rem;
                        }
                    }
                }
            }
        }
    }
}
</style>