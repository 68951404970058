<template>
    
    <div class="about-us">
        <a name="chisiamo"></a> <!-- ANCORA -->
        <img src="@/assets/images/content_img/fv.jpg" class="img-about" alt="">
        <div class="paragrafo">
            
            <div class="title watch">Chi Siamo</div>
            <div class="wrap">
                <div class="intestazione">
                    <div class="main-text">
                     L'impresa per lo sviluppo sostenibile. Partecipiamo a progetti energetici
                     e ambientali per abbattere la carbon footprint e migliorare la qualità della vita.
                    </div>  
                    
                    <text>
                    Supportiamo la costituzione di Comunità Energetiche Rinnovabili e
                    l'installazione di sistemi Agrivoltaici, impianti Fotovoltaici e
                    parchi Agrisolare.
                    </text>
                </div>               
            </div>

        </div>
    </div>
        
</template>

<script>


export default {

    name: 'AboutUs',
}

</script>

<style lang="scss" scoped>

.about-us {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;

    .img-about {
        display: flex;
        min-height: 50vh;
        flex-basis: 50%;
        flex-grow: 1;
        margin: 0 auto;
        object-fit: cover;
        object-position: 20% center;
        opacity: 0.8;
        overflow-x: hidden;
    }

    .paragrafo {
        display: flex;
        flex-basis: 50%;
        flex-direction: column;

        .title {
            display: flex;      
            height: fit-content;    
            font-family: 'Ubuntu', sans-serif;
            font-size: 3rem;
            color: var(--text-navbar);
            padding: 3rem 3rem 3rem 3rem;         
        }
    }
    
    .intestazione {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 360px;
        padding: 0rem 3rem 3rem 3rem;
        font-family: 'Ububtu', 'Roboto', sans-serif;
        font-weight: 300;
        color: var(--text-navbar-hover);

        .main-text {
            display: flex;
            width: 100%;
            font-family: 'Ubuntu', sans-serif;
            font-size: 2.5rem;
            font-weight: 400;
            line-height: 3rem;
        }
        text {
            padding: 3rem 0rem 1rem 0rem;
            font-family: 'Montserrat', sans-serif;
            line-height: 2.5rem;
            font-weight: 300;
            font-size: 1.5rem;
            color: var(--text-navbar);
        }

        
    }   
}


@media screen and (max-width: 1024px)  {

    @media screen and (max-width: 720px) {

        .paragrafo {
                min-width: 100%;
            }

        @media screen and (max-width: 540px ) {
            .about-us {
                .paragrafo{
                    min-width: 100vw;

                    .main-text {
                        font-size: 1.5rem;
                        line-height: 2rem;
                    }

                    text {
                        font-size: 1.2rem;
                        line-height: 1.75rem;
                        padding: 2rem 0rem 1rem 0rem;
                    }
                }
            }
        }
    }    
}    
</style>